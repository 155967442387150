p
<template>
  <div>
    <Navbar />

    <div class="px-6 py-10 md:px-12 md:py-20 flex-1">
      <div class="flex items-start justify-between mb-10">
        <div>
          <div class="flex items-center gap-5 mb-5">
            <h1 class="text-2xl 2xl:text-4xl font-semibold">
              Serata "{{ name }}"
            </h1>
            <router-link
              :to="{
                name: 'SessionEdit',
                params: { id: id, name: name },
              }"
              class="btn btn--red px-2 py-1"
            >
              Modifica
            </router-link>
          </div>

          <p v-if="date" class="text-sm flex items-center gap-2 mb-2">
            <IconCalendar />
            {{ dateTime(date) }}
          </p>
          <p v-if="status" class="text-sm">
            Stato serata: <strong>{{ status }}</strong>
          </p>
        </div>

        <div class="flex gap-2">
          <router-link :to="{ name: 'Sessions' }" class="btn btn--red"
            >&larr; Torna alle Serate</router-link
          >
        </div>
      </div>

      <div class="grid mt-20 grid-cols-2 gap-10 mb-20">
        <section>
          <h2 class="text-2xl font-extrabold mb-3">Canzoni da suonare</h2>

          <div v-if="songPicks.length" class="grid md:grid-cols-2 gap-4">
            <div
              v-for="pick in notPlayedSongs"
              :key="pick.id"
              class="p-2 h-full overflow-hidden flex gap-4 bg-white bg-opacity-5 rounded-lg"
            >
              <figure v-if="pick.song.image">
                <img
                  :src="pick.song.image"
                  :alt="pick.song.name"
                  class="w-24 lg:w-36 mb-2"
                />

                <button
                  @click="playSong(pick.id)"
                  class="btn btn--red px-2 py-1"
                >
                  Suona
                </button>
              </figure>

              <div>
                <h3
                  v-if="pick.song.name"
                  class="text-xl lg:text-2xl font-semibold"
                >
                  {{ pick.song.name }}
                </h3>
                <p v-if="pick.song.artist">
                  Artista: <strong>{{ pick.song.artist }}</strong>
                </p>
                <p v-if="pick.song.name">
                  Scelto da: <strong>{{ pick.name }}</strong>
                </p>
              </div>
            </div>
          </div>

          <div v-else>Nessuna canzone scelta per questa serata.</div>
        </section>

        <section>
          <h2 class="text-2xl font-extrabold mb-3">Canzoni suonate</h2>
          <div v-if="playedSongs.length">
            <div v-for="pick in playedSongs" :key="pick.id" class="mb-4">
              <span
                v-if="isPlayedSong(pick)"
                class="p-2 h-full overflow-hidden flex gap-4 bg-white bg-opacity-5 rounded-lg"
              >
                <figure v-if="pick.song.image">
                  <img
                    :src="pick.song.image"
                    :alt="pick.song.name"
                    class="w-24 lg:w-36 mb-2"
                  />
                </figure>

                <div>
                  <h3
                    v-if="pick.song.name"
                    class="text-xl lg:text-2xl font-semibold"
                  >
                    {{ pick.song.name }}
                  </h3>
                  <p v-if="pick.song.artist">
                    Artista: <strong>{{ pick.song.artist }}</strong>
                  </p>
                  <p v-if="pick.song.album">
                    Album: <strong>{{ pick.song.album }}</strong>
                  </p>
                  <p v-if="pick.song.name">
                    Scelto da: <strong>{{ pick.name }}</strong>
                  </p>

                  <ul class="mt-5 text-xs">
                    <li v-if="pick.id">ID: {{ pick.id }}</li>
                    <li v-if="pick.sessionId">
                      ID Sessione: {{ pick.sessionId }}
                    </li>
                    <li v-if="pick.userId">ID Utente: {{ pick.userId }}</li>
                    <li v-if="pick.songId">ID Canzone: {{ pick.songId }}</li>
                    <li v-if="pick.song.spotifyId">
                      Spotify ID: {{ pick.song.spotifyId }}
                    </li>
                  </ul>
                </div>
              </span>
            </div>
          </div>

          <div v-else>Nessuna canzone suonata per questa serata.</div>
        </section>
      </div>

      <h2 class="text-2xl font-extrabold mb-5">Playlist</h2>

      <div
        v-if="playlists.length"
        class="grid md:grid-cols-2 xl:grid-cols-3 gap-4 xl:gap-10"
      >
        <div
          v-for="playlist in playlists"
          :key="playlist.id"
          class="flex gap-4 justify-between p-2 bg-white bg-opacity-5 rounded-lg"
        >
          <router-link
            :to="{
              name: 'Songs',
              params: {
                id: playlist.id,
                name: playlist.name,
                description: playlist.description,
                image: playlist.image,
                spotifyId: playlist.spotifyId,
              },
            }"
            class="flex justify-between items-center"
          >
            <img
              class="w-24 lg:w-36"
              :src="playlist.image"
              :alt="playlist.name"
            />

            <div class="ml-4">
              <h3 class="text-xl lg:text-2xl font-semibold">
                {{ playlist.name }}
              </h3>
              <div class="text-gray-400">
                {{ playlist.description }}
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div v-else>Nessuna playlist scelta per questa serata.</div>
    </div>
    <Footer />
  </div>
</template>

<script>
import moment from "moment";

import Footer from "@/components/Footer.vue";
import Navbar from "@/components/Navbar.vue";

import IconCalendar from "@/components/icons/IconCalendar.vue";
import { getClient } from "../includes/api";

export default {
  props: ["id", "name", "date", "code", "status"],
  components: { Footer, Navbar, IconCalendar },
  data() {
    return {
      playlists: [],
      songPicks: [],
    };
  },
  computed: {
    playedSongs() {
      return this.songPicks.filter((songPick) => {
        return songPick.played;
      });
    },
    notPlayedSongs() {
      return this.songPicks.filter((songPick) => {
        return !songPick.played;
      });
    },
  },
  methods: {
    dateTime(value) {
      return moment(value).format("DD MMMM YYYY");
    },
    playSong(id) {
      let pickKey = this.songPicks.findIndex((songPick) => {
        return songPick.id === id;
      });
      let currentSongPick = this.songPicks;
      currentSongPick[pickKey].played = true;
      this.songPicks = currentSongPick;
    },
    isPlayedSong(pick) {
      return pick.played;
    },
  },
  async mounted() {
    try {
      let id = this.$props.id;
      console.log("Getting token...");
      const apiClient = getClient();
      console.log("Token: " + token);
      apiClient.setToken(token);
      console.log("Getting playlists...");
      let playlists = await apiClient.getSessionPlaylists(id);
      if (Array.isArray(playlists)) {
        this.playlists = playlists;
      }

      let songPicks = await apiClient.getSongPicks(id);
      if (Array.isArray(songPicks)) {
        songPicks = songPicks.map((obj) => ({ ...obj, played: false }));
        this.songPicks = songPicks;
      }
    } catch (err) {
      console.error(err);
    }
  },
};
</script>
